/*
 * Monochrome is a simple, responsive blog theme built for Jekyll.
 */

/*- Base reset -*/

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, img {
  margin: 0;
  padding: 0;
  border: 0;
}

body {

}

/*- Base color -*/

$background-color: #FFFFFF;
$text-color: #333333;
$text-light-color: rgba(0,0,0,0.6);
$text-dark-color: #000000;

/*- Base settings -*/

html {
	background-color: $background-color;
	font-size: 16px;
	@media (min-width: 940px) {
		font-size: 18px;
	}
	line-height: 1.8;
	color: $text-color;
}

body {
 max-width: 1000px;
 margin: 0 auto;
 padding: 0 10px;
}

/*- Link -*/
a {
	outline: 0;
	color: $text-color;
	text-decoration: none;
	font-weight: 700;
	&:hover,
	&:focus {
		color: darken($text-color, 5%);
	}
}

/* Common classes */

.f-right { float: right; }
.f-left { float: left; }
.clear { clear:both; }

// to align div vertically and horizontally in center of another div
.parent { display: flex;}
.inner { align-self: center; }

// justify
.justify-center { justify-content: center; }
.justify-spaceBetween { justify-content: space-between; }

.w100 { width: 100% }
.h100 { height: 100% }
.wh100 { width: 100%; height: 100%}

// position
.absolute { position: absolute; }
.relative { position: relative; }
.top { top: 0 }
.bottom { bottom: 0 }
.right { right: 0 }
.left { left: 0 }
